import HL from '@react-pdf-viewer/highlight';
import { FilterValue } from 'antd/es/table/interface';
import { ReactNode } from 'react';

export interface DepositionFact {
  id: number;
  description: string;
  citation: string;
  is_hidden: boolean;
  topic_id: number;
}

export interface DepositionTopic {
  id: number;
  description: string;
  facts: DepositionFact[];
  deposition_id: number;
}

export interface DepositionSummary {
  id: number;
  title: string;
  matter_id: number;
  file_id: number;
  citation_page_offset: number;
}

export interface MedicalSummary {
  id: number;
  title: string;
  matter_id: number;
  file_id: number;
  citation_page_offset: number;
}

export interface MedicalSummaryEvent {
  id: number;
  date: string;
  provider: string;
  encounters: string;
  citations: string;
}

export interface Tag {
  created_at: string;
  tag_value: string;
  value: string;
  tag_type: string;
  id: number;
}

export interface People {
  email: string;
  matter_id: number;
  id: number;
  last_name?: string;
  first_name: string;
  phone_number: string;
}

export interface Matter {
  id: number;
  name: string;
  slug: string;
  search_kb_uid: string;
  search_api_key: string;
  depositions: DepositionSummary[];
  medical_summaries: MedicalSummary[];
  files: File[];
  people: People[];
}

export interface Pdf {
  category: string;
  doc_path: string;
  tags: string[];
  matter_tags: string[];
  matter_id: string | number;
  doc_id: string | number;
  citations: Citation[];
  reviewer_name: string | null;
  is_reviewer_you: boolean | null;
  is_responsive: boolean | null;
  created_at: string | null;
  updated_at: string | null;
  created_by: string | null;
  doc_full_path: string;
  is_discovery: boolean;
  ocr_job_id: string;
  ocr_job_status: string | null;
  page_types: string[] | null;
  progress: number;
  reviewed_at: string | null;
  reviewed_by: string | null;
  summary: any[]; // You might want to replace 'any' with a more specific type
  viewed_at: string;
  claims: {
    coa_id: string | number;
    name: string;
    seq: number;
    created_at: string | null;
    updated_at: string | null;
    elements: Element[];
    instruction: string;
    instruction_id: string | number;
    is_hidden: boolean;
    matter_id: number;
  }[];
}

export interface Citation {
  citation_id: string | number;
  doc_id: string | number;
  fact_id: string | number | null;
  starting_page: number | null;
  cleaned_text: string;
  pdfjs_location: HL.HighlightArea[] | null;
  created_by: string | number | null;
  created_at: string | null;
  updated_at: string | null;
  facts?: Fact[];
  events?: Fact[];
  comments?: {
    comment_id: number;
    citation_id: number;
    text: string;
    created_by: string | number | null;
    created_at: string | number | null;
    updated_at: string | number | null;
  }[];
  force?: string; // set force to an hlclass type to force it to display :)
  original_text: string | null;
}

export interface Fact {
  fact_id: number;
  doc_id: number | null;
  chunk_id: number | null;
  text: string;
  event_at: string | null;
  created_by: string | null;
  created_at: string | number | null;
  updated_at: string | null;
  validation: null;
  elements?: (Element & { coa_seq: string })[];
}

export interface Element {
  batch_num: number;
  coa_element_id: number;
  coa_id: number;
  created_at: string;
  element_num: number;
  gen_id: string;
  result: string;
  round: number;
  sof: string;
  updated_at: string;
}

export interface EvidenceTool {
  type: 'evidence';
  coa_id: string;
  element_id: string;
  fact: string;
}

export interface CommentTool {
  type: 'comment';
  comment: string;
}

export interface EventTool {
  type: 'event';
  eventDate: string;
  eventTime: string;
  fact: string;
}

export interface RowPeople {
  id: number;
  last_name?: string;
  first_name: string;
}

export interface SummaryDataType {
  key: string;
  id: number;
  topicId: number;
  topic: string;
  facts: string;
  tag: string;
  isHidden?: boolean;
  showTopic: boolean;
}

export type DocLibraryFilters = Record<string, FilterValue | null>;

export interface EventType {
  file_id: number;
  created_at: string;
  datetime: string;
  description: string;
  document: Document;
  id: number;
  updated_at: string | null;
  is_hidden?: boolean;
  citations?: Array<{
    created_at: string;
    document_id: number;
    end_page: number;
    event_id: number;
    id: number;
    start_page: number;
    updated_at: string;
  }>;
}

export type EventsFilters = {
  docTypes: string[];
  people: string[];
  tags: string[];
  startDate?: string;
  endDate?: string;
  pageSize?: number;
  page?: number;
};

export type DocumentFilters = {
  types: string[];
  tags: string[];
  people: string[];
};

export enum FeatureFlags {
  DateTimeFilter = 'date-time-filter',
  ChatWithDoc = 'chat-with-doc',
  MedicalTimelines = 'medical-timelines',
  DocViewerSummary = 'doc-viewer-summary',
  DocumentTypeEditing = 'document-type-editing',
  BarChartFilter = 'bar-chart-filter'
}

export type DropdownFilterType = FilterValue | string | number;

export type DropdownOption = {
  value: string | number;
  label: string | string[];
};

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export type Notification = {
  type: NotificationType;
  title: string;
  description: ReactNode;
  placement?:
    | 'bottomRight'
    | 'top'
    | 'topLeft'
    | 'topRight'
    | 'bottom'
    | 'bottomLeft';
};

export type Document = {
  id: number;
  key: string;
  doc_type: string;
  end_page: number;
  start_page: number;
  summary: string;
};

export type File = {
  key: number;
  name: string;
  documents: Array<Document>;
  id: number;
  file_path: string;
  nuclia_resource_id: string;
  summary: string | null;
  doc_type: string;
};

export type Folder = {
  id: number;
  name: string;
  doc_type: string;
  key: string;
};

export type Library = {
  files: Array<File>;
  folders: Array<Folder>;
};

export type FiltersData = {
  doc_types: string[];
  people: Array<{ first_name: string; last_name: string; id: number }>;
  tags: Array<{ tag_value: string; id: number; tag_type: string }>;
};

export interface Task {
  task_id: number;
  type: string;
  file_id: number;
  file_path: string;
  draft: string;
  created_at: string;
  priority: number;
  status: string;
}
