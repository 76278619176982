import { datadogRum } from '@datadog/browser-rum';

let configured = false;

export function startAnalytics() {
  if (process.env.ENV === 'local') {
    return;
  }

  const env = process.env.ENV;

  if (!env) {
    throw new Error('ENV not defined');
  }

  const service = `${process.env.ENV}-amicuslaw`;

  if (configured) {
    return;
  }

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? '',
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
    site: 'us5.datadoghq.com',
    service,
    env
  });

  configured = true;
}
