import fetchApi from './fetchApi';

const ENV = process.env.ENV;
const JWT_STORAGE_KEY = `amicuslaw-ai-jwt-${ENV}`;
export const DOCUMENT_LIB_STORAGE_KEY = `document-library-path-${ENV}`;

export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  const res = await fetchApi(`/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  });

  if (res.status === 200) {
    const { access_token: token = null } = await res.json();
    // TODO: Handle error gracefully
    if (!token) {
      throw new Error('No token found in response');
    }
    localStorage.setItem(JWT_STORAGE_KEY, token);
  }

  return res.status;
};

export const hasLogin = () => {
  return !!localStorage.getItem(JWT_STORAGE_KEY);
};

export const logout = async () => {
  localStorage.removeItem(JWT_STORAGE_KEY);
  localStorage.removeItem(DOCUMENT_LIB_STORAGE_KEY);
};

export async function fetchWithAuth(
  url: string,
  options: RequestInit = {},
  isFile?: boolean
) {
  const res = await fetchApi(
    url,
    Object.assign(options, {
      headers: {
        ...(options?.headers || {}),
        Authorization: `Bearer ${localStorage.getItem(JWT_STORAGE_KEY)}`
      }
    })
  );

  if (res.status === 401) {
    throw new Error('Unauthorized');
  }

  if (res.status === 500) {
    throw res;
  }

  if (options.method === 'DELETE') {
    return res;
  }

  if (isFile) return res.blob();

  return res.json();
}
