import '../styles/globals.css';
import React, {
  ComponentType,
  ReactElement,
  ReactNode,
  useEffect
} from 'react';
import { ConfigProvider, theme } from 'antd';
import { NextPage } from 'next';
import { MenuStyles, TableStyles } from '@/utils/componentsColors';
import { hasLogin } from '@/http/auth';
import { useRouter } from 'next/router';
import { startAnalytics } from '@/utils/analytics';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@/utils/types';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppLayoutProps = {
  Component: NextPageWithLayout;
  pageProps: any;
};

const App = ({ Component, pageProps }: AppLayoutProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const router = useRouter();

  // This only handles if there is no token in localstorage on application load.
  // Additional checks are needed for authorization of routes per page
  useEffect(() => {
    startAnalytics();

    if (!hasLogin()) {
      router.push('/login');
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        components: {
          Menu: MenuStyles,
          Table: TableStyles
        }
      }}
    >
      {getLayout(<Component className="text-gray-700" {...pageProps} />)}
    </ConfigProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
  reactOptions: { useCamelCaseFlagKeys: false },
  flags: {
    [FeatureFlags.DateTimeFilter]: false,
    [FeatureFlags.ChatWithDoc]: false,
    [FeatureFlags.DocViewerSummary]: false,
    [FeatureFlags.MedicalTimelines]: false,
    [FeatureFlags.DocumentTypeEditing]: false
  }
})(App as ComponentType);
