export const MenuStyles = {
  darkItemBg: 'transparent',
  darkItemSelectedBg: '#E6F4FF',
  darkItemColor: '#000000',
  darkItemSelectedColor: '#1677FF',
  darkItemHoverColor: '#3874f6',
  darkItemHoverBg: '#e9f4fe',
  darkSubMenuItemBg: 'rgba(0,0,0,0.04)'
};

export const TableStyles = {
  headerBg: 'transparent',
  headerBorderRadius: 5,
  colorBgContainer: 'transparent'
};

export const timelineTableColors = ['yellow', 'orange', 'violet'];
